<!-- 文件中文名: 经销商注册 -->
<template>
  <div class="MemberRegister app-container" style="text-align: left">
    <el-form
        ref="registerRef"
        :model="registerInfo"
        :rules="rules"
        label-width="auto"
        size="mini"
    >
      <!-- 基本信息 -->
      <h3 style="color: #b3640d">{{ $t('Common.basicInfomation') }}</h3>
      <!-- 编号方式 -->
      <!--      <el-form-item :label="$t('Number_way')+':'" required>-->
      <!--        &lt;!&ndash; 自定义后三位 &ndash;&gt;-->
      <!--        <el-radio v-model="memberNoType" label="1">{{ $t('Custom.after_three') }}</el-radio>-->
      <!--        &lt;!&ndash; 自定义全部 &ndash;&gt;-->
      <!--        <el-radio v-model="memberNoType" label="2">{{ $t('Custom.whole') }}</el-radio>-->
      <!--      </el-form-item>-->
      <!-- 推荐人编号 -->
      <el-form-item :label="$t('mi.sellerNo') + ':'" prop="recommendNo">
        <el-input v-model.trim="registerInfo.recommendNo" @input="value=>registerInfo.recommendNo=value.toUpperCase()" clearable style="width: 80%" @blur="recommendNoBlur($event.target.value)"/>
        <span :class="{ account_name_css: recommendName_span_show }" class="red-span">{{ recommendName_span }}</span>
      </el-form-item>
      <!-- 安置人编号 -->
      <el-form-item :label="$t('mi.servicerNo_es_ES') + ':'" prop="linkNo">
        <el-input v-model.trim="registerInfo.linkNo" @input="value=>registerInfo.linkNo=value.toUpperCase()" clearable style="width: 80%" @blur="tmpLinkNoBlur($event.target.value)"/>
        <span :class="{ account_name_css: tmpLinkNo_span_show }" class="red-span">{{ tmpLinkNo_span }}</span>
        <br/>
      </el-form-item>
      <!-- 位置 -->
      <el-form-item :label="$t('sys.Location')" required>
        <el-radio-group v-model="registerInfo.lr">
          <el-radio :disabled="disabled1" label="0" @change="changRadio($event)">{{ $t('common.left') }}</el-radio>
          <el-radio :disabled="disabled2" label="1" @change="changRadio($event)">{{ $t('common.right') }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- 代办处编号 -->
      <el-form-item :label="$t('miAgent.agentNo') + ':'" prop="agentNo">
        <el-select v-model="registerInfo.agentNo" style="width: 80%" :placeholder="$t('miAgent.agentNo')" @change="agentNoChange">
          <el-option v-for="i in agentNoList" :key="i.value" :label="i.value" :value="i.value" >
            <span style="float: left">{{ i.lable }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ i.value }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 会员编号 -->
      <el-form-item v-if="memberNoType==='1'" :label="$t('poOrder.memberNo')+':'" required>
        <el-input v-model="threeMemberNo" maxlength="3" style="width: 80%" :placeholder="$t('Please.enter.three.digits')"  @blur="getMemberno">
          <template slot="prepend">{{ memeberRegular || '' }}</template>
        </el-input>
        <span v-if="this.canregister=== false" style="color: red;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
        <span v-if="this.canregister=== true" style="color: green;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
      </el-form-item>
      <!-- 会员编号 -->
      <el-form-item  v-if="memberNoType==='2'" :label="$t('poOrder.memberNo')+':'" required>
        <el-input v-model="registerInfo.memberNo" style="width: 80%"
                  placeholder=" " @blur="checkMemberNo" />
        <span v-if="this.canregister=== false"
              style="color: red;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
        <span v-if="this.canregister=== true"
              style="color: green;font-weight: 700;font-size: 18px">{{ this.prohibitregistration }}</span>
      </el-form-item>
      <!-- 姓名 -->
      <el-form-item :label="$t('csServiceCenter.trueName')" required>
        <el-input v-model="registerInfo.name" :placeholder="$t('csServiceCenter.trueName')" style="width: 80%" @blur="setRecName" />
      </el-form-item>
      <!-- 电子邮件 -->
      <el-form-item :label="$t('sys.email')" prop="email" >
        <el-input v-model="registerInfo.email" :placeholder="$t('sys.email')" style="width: 80%" />
        <p style="color: red" v-if="!registerInfo.email">{{this.$t('Please.fill.in.the.email')}}</p>
      </el-form-item>
      <!-- 电话 -->
      <el-form-item :label="$t('alCompany.phone')" required>
        <el-input v-model="registerInfo.mobile" :placeholder="$t('alCompany.phone')" style="width: 80%" />
      </el-form-item>
      <!-- 身份证号 -->
      <el-form-item :label="$t('bdSendRecordToBankReport.memberIdCH')" >
          <el-input v-model="registerInfo.paperNo" :placeholder="$t('bdSendRecordToBankReport.memberIdCH')" style="width: 80%" />
        </el-form-item>
      <!-- 状态 -->
      <el-form-item :label="$t('sys.status_es_ES')" required>
        <el-select v-model="registerInfo.totalFc" clearable style="width: 110px" :placeholder="$t('mi.input.select')" @change="changeStatus($event)">
          <!--<el-option value="100"/>-->
          <el-option value="300"/>
          <el-option value="500"/>
          <el-option value="1500"/>
        </el-select>
      </el-form-item>
      <!-- 收货信息 -->
      <h3 style="color: #b3640d">{{ $t('register.us.legend.shipping') }}</h3>
      <!-- 收获方式 -->
      <el-form-item :label="$t('PoOrder.recType')" required>
        <el-radio v-model="registerInfo.recType" label="1" @change="setAgentAddr">
          <!--                代办处提货-->
          {{ this.$t('ru.please.goto.agent.toGet_es_ES') }}
        </el-radio>
        <br>
        <el-radio v-model="registerInfo.recType" label="2" @change="setCompanyAddr">
          <!--                分公司提货-->
          {{ this.$t('pick.up.from.branch.offices_es_ES') }}
        </el-radio>
        <br>
        <!--                发到经销商-->
        <el-radio v-model="registerInfo.recType" label="3" @change="setMemberAddr">
          {{ this.$t('GoodsToMember') }}
        </el-radio>
        <br>
      </el-form-item>
      <div v-if="registerInfo.recType==='1'">
        <!--          代办处提货-->
        <el-form-item :label="$t('ru.please.goto.agent.toGet')" required size="mini" style="width: 100%">
          <el-radio-group v-model="registerInfo.recNo" size="mini" style="width: 100%" value-key="agentNo">
            <div
                v-for="(item,index) in AgentGetGoodsList"
                :key="item.agentNo"
                style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                :class="{ 'under-line': index===AgentGetGoodsList.length-1 }"
                @click="agentRadioChange(item)"
            >
              <el-radio :label="item.agentNo" class="AgentRadio" style="height: auto;overflow: hidden;">
                <div style="display: inline-block">
                  <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                    {{item.companyCode}}&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;&nbsp;({{ item.agentNo }})&nbsp;&nbsp;&nbsp;{{ item.mobile }}
                  </p>
                  <p style="display: block;white-space: pre-wrap;word-break:break-word;overflow: hidden;padding-right: 80px">
                    {{ item.storeAddr }}</p>
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="registerInfo.recType==='2'">
        <!--          从分公司提货-->
        <el-form-item :label="$t('pick.up.from.branch.office_es_ES')" required size="mini" style="width: 100%">
          <el-radio-group v-model="registerInfo.outCompanycode" size="mini" style="width: 100%" value-key="agentNo">
            <div
                v-for="(item,index) in CompanyGetGoodsList"
                :key="item.City"
                :class="{ 'under-line': index===CompanyGetGoodsList.length-1 }"
                style="margin: 5px 0;width: 100%;border-top: 1px solid #ddd"
                @click="outCompanyCodeRadioChange(item)"
            >
              <el-radio :label="item.companyCode" class="AgentRadio" style="height: auto;overflow: hidden;">
                <div style="display: inline-block">
                  <p>{{ $t(item.companyCode) }}-{{ $t(item.Name) }}</p>
                  <p>{{ $t(item.Addr) }}</p>
                </div>
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="registerInfo.recType==='3'">
        <!--          发票详细地址-->
        <el-form-item :label="$t('Invoice.detailed.address')" size="mini" style="width: 100%">
          <el-input v-model="registerInfo.invoiceAddr" :readonly="registerInfo.recType==='1'"/>
        </el-form-item>
        <!--          发票地址-->
        <el-form-item :label="$t('Invoice.address')" >
          <el-select v-model="invoiceCountryCode" value-key="companycode" filterable @change="invoiceChange" popper-class="selectAddr">
            <el-option
              v-for="item in countryList"
              :key="item.id"
              :label="item.country"
              :value="item"/>
          </el-select>
          <el-select v-model="invoiceCityCode" filterable @change="invoiceCityChange" popper-class="selectAddr">
            <el-option
              v-for="item in invoiceCityList"
              :key="item.id"
              :label="item.province"
              :value="item.id"/>
          </el-select>
        </el-form-item>
        <!--          详细地址-->
        <el-form-item :label="$t('miAgent.storeAddr')" required size="mini" style="width: 100%">
          <el-input v-model="registerInfo.recAddr" :readonly="registerInfo.recType==='1'"/>
        </el-form-item>
        <!--          地址-->
        <el-form-item :label="$t('mi.address.id')" required>
          <el-select v-model="countryCode" value-key="companycode" filterable @change="countryChange" popper-class="selectAddr">
            <el-option
                v-for="item in countryList"
                :key="item.id"
                :label="item.country"
                :value="item"/>
          </el-select>
          <el-select v-model="cityCode" filterable @change="cityChange" popper-class="selectAddr">
            <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.province"
                :value="item.id"/>
          </el-select>
        </el-form-item>
        <!--运费-->
        <el-form-item v-if="registerInfo.recType==='3'" :label="$t('shipping.cost')">250
        </el-form-item>
        <!--          收货人姓名-->
        <el-form-item :label="$t('busi.JPorder.receiverName')+(':')" required>
          <el-input v-model="registerInfo.recName" :readonly="registerInfo.recType==='1'"/>
        </el-form-item>
        <!--          收货电话-->
        <el-form-item :label="$t('ReceiveGoodsPhoneNumber')+(':')" required>
          <el-input v-model="registerInfo.recPhone" :readonly="registerInfo.recType==='1'"/>
        </el-form-item>
      </div>
      <!-- 密码设置 -->
      <h3 style="color: #b3640d">{{ $t('miAgent.title.LoginInfo') }}</h3>
      <!-- 设置密码 -->
      <el-form-item :label="$t('sysUser.password') + ':'" prop="password">
        <el-input
            v-model.trim="registerInfo.password"
            clearable
            show-password
            style="width: 80%"
            readonly onfocus="this.removeAttribute('readonly')"
        />
      </el-form-item>
      <!-- 重复密码 -->
      <el-form-item
          :label="$t('sysUser.repeatPassword') + ':'"
          prop="tmpRePassword"
      >
        <el-input
            v-model.trim="registerInfo.tmpRePassword"
            clearable
            show-password
            style="width: 80%"
            readonly onfocus="this.removeAttribute('readonly')"
        />
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-bottom: 10px">
      <!--      下一步-->
      <el-button
          v-loading="doLoading"
          icon="el-icon-right"
          size="mini"
          type="success"
          @click="btnRegisterInfoSave"
      >{{ $t('button.next') }}
      </el-button>
    </div>
  </div>
</template>

<script>

import {agentRegisterFirst, getMemberNoDateFormat, memberNoIsExit} from "@/api/register/register";
import { miMemberInfo2 } from "@/api/userInfo/userInfo";
import { agentListByCompanyCode, miMemberRightNum } from "@/api/common/common";
import {commonGetMiAgents, getRecAddrsWEU, userMemberInfo} from "@/api/rus/api";
import { mapGetters } from "vuex"

export default {
  name: 'MemberRegister',
  directives: {},
  components: {},
  data() {
    return {
      userMemberInfoObj: null,
      memberNoType: '1',
      invoiceCountryCode: {companycode: ''},
      invoiceCityCode: null,
      countryCode: {companycode: ''},
      cityCode: null,
      countryList: [],
      invoiceCityList: [],
      cityList: [],
      threeMemberNo: '',
      memeberRegular: '',
      agentNoList: [],
      prohibitregistration:'',
      canregister:false,
      CompanyGetGoodsList: [], // 分公司提货列表
      AgentGetGoodsList: [], // 提货代办处列表
      listQuery: {
        page: 1,
        limit: 20
      },
      registerInfo: {
        outCompanycode: '',
        recNo: '',
        recAddr: '',
        recPhone: '',
        recName: '',
        name: '',
        petName: '',
        agentNo: '',
        recType: '3',
        lr: ''
      },
      AgentInfoObj: null,
      CompanyInfoObj: null,
      doLoading: false,
      recommendName_span_show: false,
      tmpLinkNo_span_show: false,
      recommendName_span: '',
      tmpLinkNo_span: '',
      tmpLinkNo_num_span: '',
      memberNo_span: '',
      disabled1: true,
      disabled2: true,
      rules: {
        email: [{required: true, message: this.$t('label.pleaseFillIn'), trigger: 'blur',}],
        agentNo: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ], recommendNo: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        linkNo: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        paperType: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        paperNo: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        country: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        storeAddr: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        mobile: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        accountBank: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        accountName: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        accountCode: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
        tmpRePassword: [
          {
            required: true,
            message: this.$t('label.pleaseFillIn'),
            trigger: '[blur, change]',
          },
        ],
      },
      total: 0,
      initDict: {
        outCompanyList: 'out.company.list',
        sendfreeList: 'sendfree.weu'
      },
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'companyCode'
    ]),
    outCompanyList() {
      return this.$ll(this.initDict.outCompanyList)
    },
    getSendFree() {
      // if(!this.countryCode.companycode) {
      //   return 0
      // }
      // if(this.countryCode.companycode === 'TRA') {
      //   return this.registerInfo.totalFc >= 500 ? 0 : 2
      // }
      // if(this.countryCode.companycode !== 'TRA') {
      //   return Number(this.$lt(this.initDict.sendfreeList, this.countryCode.companycode)) || 60
      // }
      return 0
    }
  },
  async created() {
    let MemberRegisterData = sessionStorage.getItem("MemberRegisterData")
    if (MemberRegisterData) {
      this.$message.error(this.$t('There.New.Registered.Dealer').toString())
      await this.$router.push(`/allGoodsList`)
    }
    this.doLoading = true
    this.countryList = await this.getAddrList('')
    await  agentListByCompanyCode( { 'compayCode': this.companyCode } ).then(res => {
      this.agentNoList = res.data
    }).catch(() => {
      this.doLoading = false
    })
    await userMemberInfo({memberNo: this.userCode}).then(res => {
      this.userMemberInfoObj = res.data
      if (this.registerInfo.recType === '3') {
        this.setMemberAddr()
      }
    }).catch(err => {
      console.warn(err)
      console.warn('userMemberInfo失败')
    })
    await commonGetMiAgents()
        .then(res => {
          this.AgentGetGoodsList = res.data
          for(let i=0;i<this.AgentGetGoodsList.length;i++){
            if(this.AgentGetGoodsList[i].companyCode === 'RUS'){
              this.AgentGetGoodsList[i].companyCode = 'Россия'
            }
            if(this.AgentGetGoodsList[i].companyCode === 'KAZ'){
              this.AgentGetGoodsList[i].companyCode = 'Казахстан'
            }
            if(this.AgentGetGoodsList[i].agentNo === 'GEMCHUG-A'){
              this.AgentGetGoodsList[i].companyCode = 'Korea/Корея'
            }
            if(this.AgentGetGoodsList[i].companyCode === 'TJK'){
              this.AgentGetGoodsList[i].companyCode = 'Tajikistan/Таджикистан'
            }
            if(this.AgentGetGoodsList[i].companyCode === 'MNG'){
              this.AgentGetGoodsList[i].companyCode = 'Mongolia/Монголия'
            }
          }
          this.doLoading = false
        })
        .catch(err => {
          console.error(err)
          this.doLoading = false
        })
  },
  mounted() {
    if (this.companyCode === 'MEX') {
      this.CompanyGetGoodsList = [ {Name: 'MEX_Company_Name', Addr: 'MEX_Company_Addr', companyCode: 'MEX'} ] // 土耳其
    }
  },
  methods: {
    async invoiceChange(val) {
      this.invoiceCityList = await this.getAddrList(val.country)
      this.registerInfo.invoiceCode = null
      this.invoiceCityCode = null
    },
    invoiceCityChange(val) {
      this.registerInfo.invoiceCode = val
      let cityItem = this.invoiceCityList.filter(item=>{return  item.id===val})[0]
      this.registerInfo.invoiceString = cityItem.country + ' ' + cityItem.province
    },
    async countryChange(val) {
      this.cityList = await this.getAddrList(val.country)
      this.registerInfo.regionCode = null
      this.cityCode = null
    },
    cityChange(val) {
      this.registerInfo.regionCode = val
      let cityItem = this.cityList.filter(item=>{return  item.id===val})[0]
      this.registerInfo.regionString = cityItem.country + ' ' + cityItem.province
    },
    getAddrList(countryCode) {
      return new Promise((resolve, reject)=>{
        getRecAddrsWEU({country: countryCode}).then(res => {
          resolve(res.data)
        }).catch(err => {
          console.warn(err)
          reject(err)
        })
      })
    },
    agentNoChange(val) {
      getMemberNoDateFormat().then(res => {
        this.memeberRegular = val + res.data
      })
    },
    checkMemberNo() {
      let regex = /^[\w =+_-]+$/ // 只允许输入数字,大小写字母,-_+=
      if (!regex.test(this.registerInfo.memberNo)) {
        this.registerInfo.memberNo = ''
        this.prohibitregistration = 'X'
        this.canregister = false
        return false
      }
      memberNoIsExit({'memberNo': this.registerInfo.memberNo}).then(res => {
        if (res.data !== null) {
          this.prohibitregistration = 'X'
          this.canregister = false
          this.$message.error(res.data)
        } else {
          this.prohibitregistration = '✓'
          this.canregister = true
        }
      }).catch(err => {
        console.error(err)
      })
    },
    getMemberno(){
      if(!this.registerInfo.agentNo){
        this.threeMemberNo = ''
        return this.$message.error(this.$t('Please.select.an.agency.first'))
      }
      if (this.threeMemberNo && this.threeMemberNo.length === 3 ) {
        if (this.threeMemberNo === '000') {
          this.threeMemberNo = ''
          this.prohibitregistration = ' '
          this.registerInfo.memberNo = ''
          return this.$message.info(this.$t('memberNo_no_000')) // 会员编号后三位不能为000
        }
        // let regex = /^[\w =+_-]+$/ // 只允许输入数字,大小写字母,-_+=
        let regex = /^\d+$/ // 只允许输入数字
        if (regex.test(this.threeMemberNo)) {
          memberNoIsExit({'memberNo': this.memeberRegular + this.threeMemberNo}).then(res => {
            if (res.data !== null) {
              this.prohibitregistration = 'X'
              this.canregister = false
              this.$message.error(res.data)
            } else {
              this.prohibitregistration = '✓'
              this.canregister = true
              this.registerInfo.memberNo = this.memeberRegular + this.threeMemberNo
            }
          }).catch(err => {
            console.error(err)
          })
        } else {
          this.threeMemberNo = ''
          this.prohibitregistration = ' '
          this.registerInfo.memberNo = ''
        }
      } else {
        this.prohibitregistration = ' '
        this.registerInfo.memberNo = ''
      }
    },
    setAgentAddr() {
      this.registerInfo.recAddr = this.AgentInfoObj ? this.AgentInfoObj.storeAddr : ''
      this.registerInfo.recName = this.AgentInfoObj ? this.AgentInfoObj.name : ''
      // 选择代办处发货 使用注册人手机号
      this.registerInfo.recPhone = this.registerInfo.mobile
      this.registerInfo.recNo = this.AgentInfoObj ? this.AgentInfoObj.agentNo : ''
    },
    setCompanyAddr() {
      this.registerInfo.outCompanycode = this.CompanyInfoObj ? this.CompanyInfoObj.companyCode : ''
      this.registerInfo.recAddr = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Addr).toString() : ''
      this.registerInfo.recName = ''
      this.registerInfo.recPhone = this.CompanyInfoObj ? this.$t(this.CompanyInfoObj.Tel).toString() : ''
      this.registerInfo.recNo = ''
    },
    setMemberAddr() {
      this.registerInfo.recName = this.userMemberInfoObj.name
      this.registerInfo.recPhone = this.userMemberInfoObj.mobile
      this.registerInfo.recAddr = this.userMemberInfoObj.storeAddr
      this.registerInfo.recNo = ''
    },
    outCompanyCodeRadioChange(val) {
      this.registerInfo.outCompanycode = val.companyCode
      this.CompanyInfoObj = val
      this.registerInfo.recAddr = this.$t(val.Addr).toString()
      this.registerInfo.recName = ''
      this.registerInfo.recPhone = this.$t(val.Tel).toString()
      this.registerInfo.recNo = ''
    },
    formCheck() {
      if (this.memberNoType === '1') {
        if (!this.threeMemberNo) {
          this.$message.error(this.$t('memberNo.required')) // 请输入会员编号
          return false
        }
        if (this.threeMemberNo === '000') {
          this.$message.error(this.$t('memberNo_no_000')) // 会员编号后三位不能为000
          return false
        }
        if (this.threeMemberNo && this.threeMemberNo.length !== 3) {
          this.$message.error(this.$t('Please_correct_memberNo_three')) // 请输入正确的后三位会员编号
          return false
        }
      } else if (this.memberNoType === '2') {
        if (!this.registerInfo.memberNo) {
          this.$message.error(this.$t('memberNo.required')) // 请输入会员编号
          return false
        }
      }
      if (!this.registerInfo.recType) {
        this.$message.error(this.$t('info.select.shipment.type').toString())
        return false
      }
      if (this.registerInfo.recType === '3' && !this.cityCode) {
        this.$message.error((this.$t('poOrder.pleaseChoose')+this.$t('busi.address')).toString())
        return false
      }
      if (this.registerInfo.recType === '1') {
        if (!this.registerInfo.recNo) {
          this.$message.error(this.$t('Please.Selec.sa').toString())
          return false
        }
      } else if (this.registerInfo.recType === '2') {
        if (!this.registerInfo.outCompanycode) {
          this.$message.error(this.$t('Please.Selec.sb').toString())
          return false
        }
        // eslint-disable-next-line no-empty
      } else if (this.registerInfo.recType === '3') {

        if (!this.registerInfo.recAddr) {
          this.$message.error(this.$t('Please.Selec.da').toString())
          return false
        }
        if (!this.registerInfo.recName) {
          this.$message.error(this.$t('info.rec.name.required').toString())
          return false
        }
        if (!this.registerInfo.recPhone) {
          this.$message.error(this.$t('Please.Selec.pn').toString())
          return false
        }
      }

      return true
    },
    setRecName() {
      this.registerInfo.recName = this.registerInfo.name.toString()
    },
    setRecInfo(val) {
      this.registerInfo.recAddr = val.storeAddr
      this.registerInfo.recName = val.name
      this.registerInfo.recPhone = val.mobile
      this.registerInfo.recNo = val.agentNo
    },
    agentRadioChange(val) {
      this.registerInfo.recAddr = val.storeAddr
      this.registerInfo.recName = val.name
      this.registerInfo.recPhone = val.mobile
      this.registerInfo.recNo = val.agentNo
      this.AgentInfoObj = val
    },
    changeStatus(val) {
      this.registerInfo.totalFc = val
    },
    changRadio(val) {
      this.registerInfo.lr = val
    },
    btnRegisterInfoSave() {
      if (!this.formCheck()) {
        return false
      }
      if (this.registerInfo.password !== this.registerInfo.tmpRePassword) {
        return this.$message.error(this.$t('error.password.not.accord')) // 两次密码不一致
      }
      this.doLoading = true
      this.$refs['registerRef'].validate((valid) => {
        if (valid) {
          this.$confirm(this.$t('fi.sure.handle'), this.$t('common.tips'), {
            confirmButtonText: this.$t('button.confirm'),
            cancelButtonText: this.$t('operation.button.cancel'),
            customClass: 'msgBox',
            type: 'warning'
          }).then( () => {
          this.registerInfo.memberNo = this.registerInfo.memberNo.trim()
          agentRegisterFirst(this.registerInfo)
              .then((res) => {
                this.resSaveInfo = res.data
                // 点击下一步 存 状态 值
                sessionStorage.setItem('totalFc',JSON.stringify(this.registerInfo.totalFc))
                // 运费和地址信息
                res.data.getSendFree = this.getSendFree
                res.data.regionString = this.registerInfo.regionString
                res.data.invoiceString = this.registerInfo.invoiceString
                sessionStorage.setItem("MemberRegisterData", JSON.stringify(res.data))
                this.doLoading = false
                this.$router.push({path: '/allGoodsList'})
              })
              .catch(() => {
                this.doLoading = false
              })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('operation.button.cancel')
          })
          this.doLoading = false
        })
        } else {
          this.doLoading = false
        }
      })
    },
    recommendNoBlur(val) {
      if (val) {
        miMemberInfo2({code: val})
            .then((res) => {
              this.recommendName_span = res.data.name
              this.recommendName_span_show = false
            })
            .catch(() => {
              this.recommendName_span = ''
              this.recommendName_span_show = true
            })
      } else {
        this.recommendName_span = ''
        this.recommendName_span_show = false
      }
    },
    tmpLinkNoBlur(val) {
      this.registerInfo.lr = ''
      if (val) {
        miMemberRightNum({memberNo: val})
            .then((res) => {
              if (res.data.leftMem == null || res.data.leftMem == '') {
                this.disabled1 = false
              }
              if (res.data.rightMem == null || res.data.rightMem == '') {
                this.disabled2 = false
              }
              this.tmpLinkNo_span = res.data.name
              this.tmpLinkNo_num_span = res.data.num
            })
            .catch(() => {
              this.tmpLinkNo_span = ''
              this.tmpLinkNo_num_span = ''
              this.tmpLinkNo_span_show = true
            })
      } else {
        this.tmpLinkNo_span = ''
        this.tmpLinkNo_num_span = ''
        this.tmpLinkNo_span_show = false
      }
    },
  }
}

</script>

<style lang="scss" scoped>

.account_name_css:after {
  content: 'X';
  color: #ff4949;
}

.red-span {
  color: red;
}

.register_3_css {
  display: flex;

  ::v-deep .el-form-item {
    margin-bottom: 0
  }
}

.AgentRadio ::v-deep .el-radio__input {
  height: 35px;
}
.under-line {
  border-bottom: 1px solid #ddd
}
</style>
